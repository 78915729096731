import {
  RESULTS_LOADING,
  ERROR_IN_GET_RESULTS,
  GET_ALL_RESULTS,
} from "./results.action";

const initialResultstate = {
  AllResult: { nextUrl: null, results: [], total: 0 },
  isResultLoading: false,
  isResultError: null,
};

export const Reducer = (state = initialResultstate, { type, payload }) => {
  switch (type) {
    case RESULTS_LOADING:
      return {
        ...state,
        isResultLoading: true,
        isResultError: null,
      };
    case ERROR_IN_GET_RESULTS:
      return {
        ...state,
        isResultLoading: false,
        isResultError: payload,
      };
    case GET_ALL_RESULTS:
      return {
        ...state,
        isResultLoading: false,
        isResultError: null,
        AllResult: {
          ...state.AllResult,
          nextUrl: payload?.nextUrl,
          // posts: [...state.AllResult?.posts, ...payload?.admitcard],
          results: payload?.results,
          total: payload?.total,
        },
      };
    default:
      return state;
  }
};
