import { Suspense, lazy } from "react";
import { Loading } from "../components/Loading/Loading";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );

export const HomePage = Loadable(lazy(() => import("../Pages/Home/Home")));
export const JobsPage = Loadable(lazy(() => import("../Pages/Jobs/Jobs")));
export const StateJobsPage = Loadable(lazy(() => import("../Pages/Jobs/StateJobs")));
export const ResultsPage = Loadable(
  lazy(() => import("../Pages/Results/Results"))
);
export const AdmitcardsPage = Loadable(
  lazy(() => import("../Pages/Admitcard/AdmitCard"))
);
export const DetailsPage = Loadable(
  lazy(() => import("../Pages/Details/Details"))
);
export const ContactUsPage = Loadable(
  lazy(() => import("../Pages/ContactUs/ContactUs"))
);
export const AdvertisePage = Loadable(
  lazy(() => import("../Pages/Advertise/Advertise"))
);
export const PolicyPage = Loadable(
  lazy(() => import("../Pages/Policy/Policy"))
);
export const NotfoundPage = Loadable(lazy(() => import("../Pages/Others/NotFound")));
