import { FAQS_LOADING, ERROR_IN_GET_FAQS, GET_ALL_FAQS } from "./faqs.action";

const initialFaqsstate = {
  AllFaqs: { nextUrl: null, data: [], total: 0 },
  isFaqLoading: false,
  isFaqError: null,
};

export const Reducer = (state = initialFaqsstate, { type, payload }) => {
  switch (type) {
    case FAQS_LOADING:
      return {
        ...state,
        isFaqLoading: true,
        isFaqError: null,
      };
    case ERROR_IN_GET_FAQS:
      return {
        ...state,
        isFaqLoading: false,
        isFaqError: payload,
      };
    case GET_ALL_FAQS:
      return {
        ...state,
        isFaqLoading: false,
        isFaqError: null,
        AllFaqs: payload,
      };
    default:
      return state;
  }
};
