export let linkmenu = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "All Jobs",
    path: "/latest-job",
  },
  {
    name: "State Jobs",
    path: "/state-jobs",
  },
  {
    name: "Results",
    path: "/results",
  },
  {
    name: "Admit Card",
    path: "/admitcards",
  },
  // {
  //   name: "Advertise",
  //   path: "/advertise",
  // },
  {
    name: "Contact Us",
    path: "/contact-us",
  },
];
