import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";

const Card = (data) => {
  const handleClick = (jobId) => {
    if (data?.searchResult) {
      data?.onClose();
    }

   window.open(`/job-details/${jobId}`, "_blank");
  };

  return (
    <Box>
      <UnorderedList
        key={data?._id}
        margin={{ lg: "10px 30px", md: "10px 30px", base: "10px 30px" }}
      >
        <ListItem
          _hover={{ textDecoration: "underline" }}
          fontSize={{ lg: "20px", md: "18px", base: "17px" }}
          onClick={() => handleClick(data?._id)}
          cursor="pointer"
          color="blue.600"
        >
          {data?.vacancy_title}{" "}
          {data?.searchResult && (
            <ListItem as="span" color="pink.500">
              {data?.is_results_avl
                ? "-Result"
                : data?.is_admitcard_avl
                ? "-Admitcard"
                : "-Notification"}
            </ListItem>
          )}
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default Card;
