import { Box, Heading, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { socialMediaLinks } from "../../utils/constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box bg="gray.100" color="black" p={4} mt={10}>
      <Box px={4}>
        {/* Newsletter Subscription Section */}
        <Box mb={10}>
          <Heading
            as="h3"
            size={{ lg: "lg", base: "md" }}
            mb={4}
            color="blue.800"
          >
            Join our Channel On Telegram and Whatsapp For Latest Updates:
          </Heading>

          <Box
            display="flex"
            flexDirection={"row"}
            gap={5}
            marginTop={5}
            marginBottom={5}
            // alignItems="center"
            justifyContent={"center"}
          >
            {socialMediaLinks?.map((link, index) => (
              <Box as={Link} to={link.href} target="_blank" key={index}>
                <IconButton
                  icon={<link.icon />}
                  bg={link.bg}
                  color="white"
                  _hover={{ bg: link.hoverBg }}
                  isRound
                />
              </Box>
            ))}
          </Box>

          <Text mb={6} fontSize={{ base: "md", sm: "lg" }} color="gray.700">
            Get the latest updates on government jobs directly in your favorite
            social media plateform.
          </Text>
        </Box>

        {/* Footer Links and Information */}
        <Box
          textAlign="center"
          borderTop="1px"
          borderColor="gray.300"
          pt={4}
          mt={4}
        >
          <Text
            fontSize={{ base: "sm", md: "md", lg: "18px" }}
            mb={2}
            color="blue.800"
          >
            <Link to={"/policy"}>Policy</Link> |{" "}
            <Link to={"/contact-us"}>Contact Us</Link>
          </Text>
          <Text
            fontSize={{ base: "15px", md: "16px", lg: "18px" }}
            mb={2}
            textAlign={{ lg: "", base: "center" }}
            color="gray.600"
          >
            sarkarijobsamachar.com provides free job alert service to job
            seekers in India on latest government jobs, Admitcard availability
            and results. To get free job alerts daily, subscribe to our channel
            on Telegram and Whatsapp.
          </Text>
          <Text
            fontSize={{ base: "16px", md: "16px", lg: "18px" }}
            color="gray.500"
          >
            Copyright © {currentYear} sarkarijobsamachar.com All Rights
            Reserved.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
