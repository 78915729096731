import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

export const Loading = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background="rgba(255, 255, 255, 0.8)"
      zIndex={9999}
    >
      <Box>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    </Box>
  );
};
