import { axiosInstance } from "../../utils/axiosInstance";

export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_QUICK_JOBS = "GET_QUICK_JOBS";
export const GET_NEW_JOBS = "GET_NEW_JOBS";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";
export const JOB_LOADING = "JOB_LOADING";
export const ERROR_IN_GET_JOB = "ERROR_IN_GET_JOB";
export const SEARCH = "SEARCH";

// Get All Jobs API
export const GetALLJobs = (query) => {
  return async (dispatch) => {
    dispatch({ type: JOB_LOADING });
    try {
      const res = await axiosInstance.get("/api/v1/posts", {
        params: query,
      });
      const jobs = res?.data;
      if (jobs.status === 201) {
        dispatch({ type: GET_ALL_JOBS, payload: jobs?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_JOB, payload: jobs?.message });
      }
    } catch (error) {
      // console.log("GetALLJobs", error?.response?.data);
      dispatch({ type: ERROR_IN_GET_JOB, payload: error?.response?.data });
    }
  };
};

// Get Job Details API
export const GetJobDetils = (id) => {
  return async (dispatch) => {
    dispatch({ type: JOB_LOADING });
    try {
      const res = await axiosInstance.get(`/api/v1/posts/${id}`);
      const jobs = res?.data;
      // console.log("GetJobDetils",jobs);
      if (jobs.status === 201) {
        dispatch({ type: GET_JOB_DETAIL, payload: jobs?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_JOB, payload: jobs?.message });
      }
    } catch (error) {
      // console.log("Get job details", error?.response?.data);
      dispatch({ type: ERROR_IN_GET_JOB, payload: error?.response?.data });
    }
  };
};

export const GetQuickOrNewJobs = (query) => {
  return async (dispatch) => {
    dispatch({ type: JOB_LOADING });
    try {
      const res = await axiosInstance.get(`/api/v1/posts/quick/new`, {
        params: query,
      });
      const jobs = res?.data;
      // console.log("GetQuickOrNewJobs", jobs, query);
      if (jobs.status === 201 && query?.posts_type === "quick") {
        dispatch({ type: GET_QUICK_JOBS, payload: jobs?.data });
      } else if (jobs.status === 201 && query?.posts_type === "new") {
        dispatch({ type: GET_NEW_JOBS, payload: jobs?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_JOB, payload: jobs?.message });
      }
    } catch (error) {
      // console.log("GetQuickOrNewJobs", error?.response?.data);
      dispatch({ type: ERROR_IN_GET_JOB, payload: error?.response?.data });
    }
  };
};

export const SearchJobs = (query) => {
  return async (dispatch) => {
    dispatch({ type: JOB_LOADING });
    try {
      const res = await axiosInstance.get(`/api/v1/posts/action/search`, {
        params: query,
      });
      const jobs = res?.data;

      if (jobs.status === 201) {
        dispatch({ type: SEARCH, payload: jobs?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_JOB, payload: jobs?.message });
      }
    } catch (error) {
      // console.log("SearchJobs", error?.response?.data);
      dispatch({ type: ERROR_IN_GET_JOB, payload: error?.response?.data });
    }
  };
};
