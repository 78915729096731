import {
  ADMITCARD_LOADING,
  ERROR_IN_GET_ADMITCARD,
  GET_ALL_ADMITCARD,
} from "./admitcard.action";

const initialAdmitcardstate = {
  AllAdmitcard: { nextUrl: null, admitcard: [], total: 0 },
  isAdmitcardLoading: false,
  isAdmitcardError: null,
};

export const Reducer = (state = initialAdmitcardstate, { type, payload }) => {
  switch (type) {
    case ADMITCARD_LOADING:
      return {
        ...state,
        isAdmitcardLoading: true,
        isAdmitcardError: null,
      };
    case ERROR_IN_GET_ADMITCARD:
      return {
        ...state,
        isAdmitcardLoading: false,
        isAdmitcardError: payload,
      };
    case GET_ALL_ADMITCARD:
      return {
        ...state,
        isAdmitcardLoading: false,
        isAdmitcardError: null,
        AllAdmitcard: {
          ...state.AllAdmitcard,
          nextUrl: payload?.nextUrl,
          // admitcard: [...state.AllAdmitcard?.posts, ...payload?.admitcard],
          admitcard: payload?.admitcard,
          total: payload?.total,
        },
      };
    default:
      return state;
  }
};
