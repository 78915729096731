import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, IconButton, Link, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaTelegram } from "react-icons/fa";
import { socialMediaLinks } from "../../utils/constants";

const FloatingSocialMedia = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
      <Box
        position="fixed"
        right="0"
        bottom="0"
        transform="translateY(-50%)"
        zIndex={10}
      >
        <VStack
          bg="white"
          boxShadow="md"
          borderRadius="md"
          p={2}
          spacing={4}
          alignItems="end"
        >
          {/* Expand/Collapse Button */}
          <IconButton
            icon={isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
            onClick={toggleExpand}
            color="gray.600"
            _hover={{ bg: "gray.400" }}
            isRound
          />

          {/* Only show the first icon initially */}
          <Link href={socialMediaLinks[0].href} target="_blank">
            <IconButton
              icon={<FaTelegram />}
              bg={socialMediaLinks[0].bg}
              color="white"
              _hover={{ bg: socialMediaLinks[0].hoverBg }}
              isRound
            />
          </Link>

          {/* Conditionally render other icons */}
          {isExpanded &&
            socialMediaLinks.slice(1).map((link, index) => (
              <Link href={link.href} target="_blank" key={index}>
                <IconButton
                  icon={<link.icon />}
                  bg={link.bg}
                  color="white"
                  _hover={{ bg: link.hoverBg }}
                  isRound
                />
              </Link>
            ))}
        </VStack>
      </Box>
    
  );
};

export default FloatingSocialMedia;
