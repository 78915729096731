import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { socialMediaLinks } from "../../utils/constants";

const SocialMedia = () => {
  const handleClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      mt={2}
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent={"center"}
      flexWrap="wrap"
    >
      {socialMediaLinks.map((socialMedia, index) => (
        <Box
          key={index}
          onClick={() => handleClick(socialMedia.href)}
          fontSize={{ lg: "16px", md: "14px", base: "12px" }}
          cursor="pointer"
          m={{ lg: 2, md: 2, base: "2px" }}
          _hover={{ bg: socialMedia.hoverBg }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={socialMedia.bg}
          color="white"
          borderRadius="20px"
          gap="10px"
          p=".5rem 1rem"
          transition="background-color 0.3s"
        >
          {/* <IconButton
            icon={<socialMedia.icon />}
            bg={socialMedia.bg}
            color="white"
            _hover={{ bg: socialMedia.hoverBg }}
            isRound
            aria-label={socialMedia.name}
          /> */}
          <socialMedia.icon />
          <Text>{socialMedia.name}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default SocialMedia;
