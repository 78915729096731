import { Box, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import png3 from "../../img/png3.png";
import { GetQuickOrNewJobs } from "../../redux/jobs/job.action";
import { randomColor } from "../../utils/commonfunc";
import { linkmenu } from "./link";
import Search from "./Search";
import SocialMedia from "./SocialMedia";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { NewJobs, isLoading, isError } = useSelector(
    (state) => state.JobReducer
  );

  const data = [
    "डेली अपडेट पाने के लिए हमारे Telegram एवं Whatsapp चैनल को Join करे और Instagram पर फॉलो भी करे।",
    "Join our Telegram and WhatsApp channels and follow us on Instagram to get daily updates.",
  ];
  useEffect(() => {
    dispatch(GetQuickOrNewJobs({ posts_type: "new" }));
  }, [dispatch]);
  return (
    <Box>
      {/* ----------------first navbar---------------- */}
      {/* where can put logo ,heading and website link */}
      <Box
        background="blue.900"
        color="white"
        display={"flex"}
        padding={"10px"}
        gap={{ lg: "20px", md: "30px", base: "5px" }}
        alignItems={"center"}
        w={{ lg: "100%", md: "100%", base: "100%" }}
        height={{ lg: "150px", md: "130px", base: "100px" }}
      >
        {/* ---------------logo section ---------------- */}
        <Box
          w={{ lg: "30%", md: "22%", base: "25%" }}
          h={{ lg: "100%", md: "100%", base: "100%" }}
          justifyContent={"center"}
        >
          <Image
            src={png3}
            alt="sarkari job samachar - Logo"
            w={"100%"}
            height={"100%"}
            objectFit={"contain"}
          />
        </Box>
        <Box textAlign={"center"}>
          {" "}
          <Heading
            as="h1"
            fontSize={{ lg: "35px", md: "25px", base: "16px" }}
            fontFamily={' "Bona Nova SC", serif'}
            cursor={"pointer"}
            onClick={() => navigate(`/`)}
          >
            SARKARI JOB SAMACHAR
          </Heading>
          <Text
            fontSize={{ lg: "23px", md: "18px", base: "13px" }}
            // mt={"10px"}
            // cursor={"pointer"}
            to="/"
            color={"yellow"}
            // fontFamily={' "Bona Nova SC", serif'}
          >
            Connecting Talent with Opportunity
          </Text>
        </Box>
      </Box>
      {/* /* -------------others link------------------- */}
      {/* // ---------------------- for example Home,Latest Jobs Results Admit Card Answer Key Syllabus Search Contact Us---------------- */}

      <Flex
        paddingLeft={{ lg: "15px", base: "0px" }}
        bg="black"
        textAlign={"center"}
        gap={{ lg: "20px", md: "15px", base: "2px" }}
        fontSize={{ lg: "20px", base: "11px" }}
        fontWeight={{ lg: "500", base: "300", md: "500" }}
      >
        {linkmenu.map((link, index) => (
          <Box as={RouterLink} to={link.path} color="white" p="5px" key={index}>
            {link.name}
          </Box>
        ))}
      </Flex>

      {/* --------------------marquee---------------latest job ------- */}
      <Box
        m={"1.5rem 0"}
        fontSize={{ lg: "18px", md: "", base: "" }}
        color={"blue"}
      >
        {!isLoading && isError === null && (
          <Marquee pauseOnHover>
            <Text
              color={"white"}
              fontSize={"10px"}
              margin={"0px 5px"}
              padding={"0px 5px"}
              fontWeight={"bold"}
              borderRadius={"30px"}
              background={"red"}
            >
              new
            </Text>
            {NewJobs?.posts?.map((elem) => {
              return (
                <Box
                  display={"flex"}
                  flexDir={"row"}
                  gap={"5px"}
                  key={elem._id}
                  onClick={() =>
                    window.open(`/job-details/${elem._id}`, "_blank")
                  }
                >
                  {" "}
                  <Link ml={"5px"}>{elem.heading}</Link>{" "}
                  <Text color={"gray"}>||</Text>
                </Box>
              );
            })}
          </Marquee>
        )}
      </Box>
      <Text
        m={"20px 0"}
        color={"red"}
        fontSize={{ lg: "20px", md: "17px", base: "12px" }}
        fontWeight={{ lg: "500", base: "500" }}
        textAlign={"center"}
      >
        Welcome to sarkari job samachar portal{" "}
      </Text>
      {/* -----------searching bar section----------------  */}
      <Search />

      <SocialMedia />

      <Marquee pauseOnHover>
        {data?.map((elem) => (
          <Text key={elem} color={randomColor()} ml={"5px"}>
            {elem}
          </Text>
        ))}
      </Marquee>
    </Box>
  );
};

export default Navbar;
