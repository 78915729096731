import { axiosInstance } from "../../utils/axiosInstance";

export const GET_ALL_ADMITCARD = "GET_ALL_ADMITCARD";
export const GET_QUICK_ADMITCARD = "GET_QUICK_ADMITCARD";
export const GET_NEW_ADMITCARD = "GET_NEW_ADMITCARD";
export const GET_ADMITCARD_DETAIL = "GET_ADMITCARD_DETAIL";
export const ADMITCARD_LOADING = "ADMITCARD_LOADING";
export const ERROR_IN_GET_ADMITCARD = "ERROR_IN_GET_ADMITCARD";

// Get All Admitcard API
export const GetALLAdmitcard = (query) => {
  return async (dispatch) => {
    dispatch({ type: ADMITCARD_LOADING });
    try {
      const res = await axiosInstance.get("/api/v1/admitcard", {
        params: query,
      });
      const Admitcard = res?.data;
      // console.log("Admitcard", Admitcard);
      if (Admitcard.status === 201) {
        dispatch({ type: GET_ALL_ADMITCARD, payload: Admitcard?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_ADMITCARD, payload: Admitcard?.message });
      }
    } catch (error) {
      // console.log("GetALLAdmitcard", error?.response?.data);
      dispatch({
        type: ERROR_IN_GET_ADMITCARD,
        payload: error?.response?.data,
      });
    }
  };
};
