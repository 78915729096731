import { axiosInstance } from "../../utils/axiosInstance";

export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const FAQS_LOADING = "FAQS_LOADING";
export const ERROR_IN_GET_FAQS = "ERROR_IN_GET_FAQS";

// Get All Faqs API
export const GetALLFaqs = () => {
  return async (dispatch) => {
    dispatch({ type: FAQS_LOADING });
    try {
      const res = await axiosInstance.get("/api/v1/faqs");
      const Faqs = res?.data;
      if (Faqs.status === 200) {
        dispatch({ type: GET_ALL_FAQS, payload: Faqs?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_FAQS, payload: Faqs?.message });
      }
    } catch (error) {
      dispatch({
        type: ERROR_IN_GET_FAQS,
        payload: error?.response?.data,
      });
    }
  };
};
