import { axiosInstance } from "../../utils/axiosInstance";

export const GET_ALL_RESULTS = "GET_ALL_RESULTS";
export const GET_QUICK_RESULTS = "GET_QUICK_RESULTS";
export const GET_NEW_RESULTS = "GET_NEW_RESULTS";
export const GET_RESULTS_DETAIL = "GET_RESULTS_DETAIL";
export const RESULTS_LOADING = "RESULTS_LOADING";
export const ERROR_IN_GET_RESULTS = "ERROR_IN_GET_RESULTS";

// Get All Result API
export const GetALLResult = (query) => {
  return async (dispatch) => {
    dispatch({ type: RESULTS_LOADING });
    try {
      const res = await axiosInstance.get("/api/v1/results", {
        params: query,
      });
      const Result = res?.data;
      if (Result.status === 201) {
        dispatch({ type: GET_ALL_RESULTS, payload: Result?.data });
      } else {
        dispatch({ type: ERROR_IN_GET_RESULTS, payload: Result?.message });
      }
    } catch (error) {
      // console.log("GetALLResult", error?.response?.data);
      dispatch({
        type: ERROR_IN_GET_RESULTS,
        payload: error?.response?.data,
      });
    }
  };
};
