import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { thunk } from "redux-thunk";
import { Reducer as JobReducer } from "./jobs/job.reducer";
import { Reducer as ResultReducer } from "./results/results.reducer";
import { Reducer as AdmitcardReducer } from "./admitcard/admitcard.reducer";
import { Reducer as FaqsReducer } from "./faqs/faq.reducer";

const rootReducer = combineReducers({
  JobReducer,
  ResultReducer,
  AdmitcardReducer,
  FaqsReducer,
});
export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
