import {
  ERROR_IN_GET_JOB,
  GET_ALL_JOBS,
  GET_JOB_DETAIL,
  GET_NEW_JOBS,
  GET_QUICK_JOBS,
  JOB_LOADING,
  SEARCH,
} from "./job.action";

const initialJobState = {
  AllJobs: { nextUrl: null, posts: [], total: 0 },
  QuickJobs: { nextUrl: null, posts: [], total: 0 },
  NewJobs: { nextUrl: null, posts: [], total: 0 },
  Search: { nextUrl: null, posts: [], total: 0 },
  isLoading: false,
  isError: null,
  job_details: {},
};

export const Reducer = (state = initialJobState, { type, payload }) => {
  switch (type) {
    case JOB_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case ERROR_IN_GET_JOB:
      return {
        ...state,
        isLoading: false,
        isError: payload,
      };
    case GET_ALL_JOBS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        AllJobs: {
          ...state.AllJobs,
          nextUrl: payload?.nextUrl,
          // posts: [...state.AllJobs?.posts, ...payload?.posts],
          posts: payload?.posts,
          total: payload?.total,
        },
      };
    case GET_QUICK_JOBS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        QuickJobs: {
          ...state.QuickJobs,
          nextUrl: payload?.nextUrl,
          // posts: [...state.QuickJobs?.posts, ...payload?.posts],
          posts: payload?.posts,
          total: payload?.total,
        },
      };
    case GET_NEW_JOBS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        NewJobs: {
          ...state.NewJobs,
          nextUrl: payload?.nextUrl,
          // posts: [...state.NewJobs?.posts, ...payload?.posts],
          posts: payload?.posts,
          total: payload?.total,
        },
      };
    case GET_JOB_DETAIL:
      return {
        ...state,
        isLoading: false,
        isError: null,
        job_details: payload,
      };
    case SEARCH:
      return {
        ...state,
        isLoading: false,
        isError: null,
        Search: {
          ...state.Search,
          nextUrl: payload?.nextUrl,
          // posts: [...state.Search?.posts, ...payload?.posts],
          posts: payload?.posts,
          total: payload?.total,
        },
      };
    default:
      return state;
  }
};
